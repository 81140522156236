<script>
import appConfig from "@/app.config";
import router from "../../index";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

export default {
  page: {
    title: "Lockscreen",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BACKEND_URL_VERSION,
      session: JSON.parse(Cookies.get("session")),
      username: Cookies.get("session_username"),
    };
  },
  mounted() {
    // this.logout();
  },
  methods: {
    logout() {
      Cookies.remove("session");
      Cookies.remove("session_username");
      Cookies.remove("session_nama");
      Cookies.remove("session_id");
      Cookies.remove("session_role_id");
      Cookies.remove("session_role_slug");
      router.push('/login');
    },
    check() {
      // if (this.session) //console.log(this.session);
      // else {
      //console.log("kosong");
      // }
    },
    tryLogin() {
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION + "api/account/lockscreen",

          {
            username: this.username,
            password: this.password,
          }
        )
        .then((response) => {
          if (response.data.data.session) {
            localStorage.reload_already = "no";
            localStorage.dateLogin = Date.now();
            return router.push("/");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginProcess() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      let self = this;
      this.submitted = true;
      // stop here if form is invalid
      var FormData = require("form-data");
      var data = new FormData();
      data.append("username", this.username);
      data.append("password", this.password);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "/api/account/act_login",
        data: data,
      };
      axios(config).then((response) => {
        var data_login = response.data;
        // check status login
        if (data_login.code != 200) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Akun tidak ditemukan!",
          });
        } else {
          Cookies.set('session', JSON.stringify(data_login.user), {
            expires: 1,
            secure: true, // Ensure cookie is sent over HTTPS
            sameSite: 'Lax' // Adjust according to your needs
          });
          Cookies.set('session_username', data_login.user.username, {
            expires: 1,
            secure: true, // Ensure cookie is sent over HTTPS
            sameSite: 'Lax' // Adjust according to your needs
          });
          Cookies.set('session_nama', data_login.user.name, {
            expires: 1,
            secure: true, // Ensure cookie is sent over HTTPS
            sameSite: 'Lax' // Adjust according to your needs
          });
          Cookies.set('session_id', data_login.user.id, {
            expires: 1,
            secure: true, // Ensure cookie is sent over HTTPS
            sameSite: 'Lax' // Adjust according to your needs
          });
          Cookies.set('session_role_id', data_login.user.role_id, {
            expires: 1,
            secure: true, // Ensure cookie is sent over HTTPS
            sameSite: 'Lax' // Adjust according to your needs
          });
          localStorage.reload_already = "no";
          localStorage.dateLogin = Date.now();

          let timerInterval;
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman dashboard segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */

            // return false;
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "default" });
            }
          });
        }
      });
    },
  },
};
</script>
<style>
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.bg-login {
  background-image: url("http://dev.alfahuma.tech:811/external/external-digicorsec-2023/public/webfile/file/6950c16c9bcc6995f376b297f163175999993.jpg");
  background-size: cover;
  background-position: center;
  max-width: 100%;
  height: auto;
  padding-top: 25px;
  padding-bottom: 30px;
}
</style>
<template>
  <div class="container bg-login">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div
          class="wrap-login100 mt-3 mb-3 p-5"
          style="background: #fff !important"
        >
          <b-form
            class="p-2"
            @submit.prevent="loginProcess"
          >
            <span class="login100-form-logo">
              <img
                src="@/assets/images/login_logo.jpg"
                width="150"
              />
            </span>
            <span class="login100-form-title p-b-15 p-t-15 mb-3">
              <b>{{ session.username }}</b>
            </span>

            <b-form-group
              class="mb-3"
              id="input-group-2"
              label="Password"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="password"
                type="password"
                placeholder="Enter password"
                :class="{
                  'is-invalid': submitted && v$.password.$error,
                }"
              ></b-form-input>
              <div
                v-if="submitted && v$.password.$error"
                class="invalid-feedback"
              >
                <span v-if="v$.password.required.$message">{{
                  v$.password.required.$message
                }}</span>
              </div>
            </b-form-group>
            <div class="mt-3 d-grid">
              <b-button
                type="submit"
                variant="primary"
                class="btn-block"
              >Log In</b-button>
            </div>
          </b-form>
          <br />
          <div class="mt-5 text-center">
            <p>
              <button
                class="fw-medium text-primar"
                @click="logout"
                type="button"
              >
                Back To Login page
              </button>
            </p>
          </div>
        </div>
        <!-- end card -->
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
</template>
